.wrapper-line-bottom {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 130px;

  &:after {
    content: "";
    height: 100px;
    width: 2px;
    background-color: $color-brand;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.wrapper-line-top {
  position: relative;
  margin-top: 30px;
  padding-top: 130px;

  &:after {
    content: "";
    height: 100px;
    width: 2px;
    background-color: $color-brand;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.wrapper-line-tb {
  border-top: 2px solid $color-brand;
  border-bottom: 2px solid $color-brand;
}

.wrapper-line-b-top {
  border-top: 2px solid $color-brand;
}