.alert {
  border-radius: 0;
}

.alert-label {
  @extend .caption1;
  text-align: center;
  
  &.alert-success {
    color: $color-success;
  }
}