.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-family: $font1-reg;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: pxToRem(14px);
  padding: pxToRem(12px) pxToRem(16px);
}

.btn-cta {
  padding: 1.188rem 1.5rem;
  min-width: 260px;;
}

.btn-brand {
  background-color: $color-brand;
  color: $color-white;

  &:hover {
    background-color: darken($color-brand, 10%);
    color: $color-white;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color-brand,.25);
  }
}

.btn-link, 
a.btn-link {
  color: $color-brand;

  &:visited {
    color: $color-brand;
  }

  &:hover {
    color: lighten($color-brand, 5);
    text-decoration: none;
  }
}

a.link-cta {
  color: $color-white;
  background-color: $color-brand;
  display: inline-block;
  font-family: $font1-reg;
  font-size: pxToRem(14px);
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 20px 30px;
  position: relative;

  &:visited {
    color: $color-white;
  }

  &:hover {
    background-color: lighten($color-brand, 5);
  }

  &.link-cta-dark {
    background-color: $color-dark;

    &:hover {
      background-color: lighten($color-dark, 5);
    }
  }

  &.--arrow {
    padding-right: 50px;
    &:after {
      content: url(../../img/mdi-arrow-right.svg);
      position: absolute;
      right: 25px;
    }
  }
}

a.link-cta-secondary {
  color: $color-brand;
  background-color: transparent;
  display: inline-block;
  font-family: $font1-reg;
  font-size: pxToRem(14px);
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 20px 30px;
  position: relative;

  &:visited {
    color: $color-brand;
  }

  &:hover {
    color: lighten($color-brand, 5);
    background-color: transparent;
  }

  &.--arrow {
    padding-right: 50px;
    &:after {
      content: url(../../img/mdi-arrow-right.svg);
      position: absolute;
      right: 25px;
    }
  }
}