.hero-section {
  background: url(../../img/lobby.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 350px;

  @include breakpoint(md) {
    min-height: 500px;
  }
}

.benefits-item {
  img {
    height: 63px;
    width: auto;
  }
}