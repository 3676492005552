.form-control {
  border-radius: 0;
  background-color: $iu-3;
  border: 1px solid rgba($color-dark, .14);
  font-family: $font1-reg;
  height: calc(2.25rem + 9px);
  color: $color-dark;

  &:focus {
    color: $color-dark;
    background-color: #fff;
    border-color: $color-brand;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($color-brand,.25);
  }
}

.form-control-lg {
  height: auto;
  padding: .5rem 1rem;
  font-size: pxToRem(18px);
  line-height: 1.5;
  padding: 1.094rem 1.5rem;
}