.navbar {
  border-bottom: 1px solid rgba($color-dark, .05);

  .nav-item {
    .nav-link {
      color: $color-dark;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.05em;
      line-height: 150%;
      text-transform: uppercase;

      &.button-link {
        background-color: $color-dark;
        color: $color-white;
        padding: 8px 24px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.navbar-brand {
  img {
    height: 40px;

    @include breakpoint(lg) {
      height: 70px;
    }
  }
}

.navbar-light {
  .navbar-toggler {
    color: rgba($color-dark,.5);
    border: none;
  }
}

.mobile-nav {
  width: 100%;
  order: 5;

  @include breakpoint(lg) {
    display: none;
  }

  .navbar-nav {
    padding-top: 10px;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}