.featured-project {
  background-color: #000;
  padding-bottom: 100px;
  padding-top: 100px;
  margin-top: 100px;

  @include breakpoint(md) {
    padding-bottom: 200px;
    padding-top: 200px;
  }

  @include breakpoint(lg) {
    height: 820px;
    margin-bottom: 200px;

    &.featured-project-md {
      height: 750px;
      margin-bottom: 300px;
    }
  }

  @include breakpoint(xl) {
    height: 920px;
    margin-bottom: 200px;

    &.featured-project-md {
      height: 720px;
      margin-bottom: 300px;
    }
  }



  .container {
    position: relative;
  }

  span, p {
    color: $color-white;
  }

  .featured-project-logo {
    width: 200px;

    @include breakpoint(lg) {
      width: 100%;
    }
  }

  .featured-project-images {
    margin-top: 30px;

    @include breakpoint(md) {
      margin-top: 70px;
    }
  }
}