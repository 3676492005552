// @font-face {
//   font-family: 'Lato-Light';
//   src: url('/fonts/Lato/Lato-Light.ttf')  format('truetype'); /* Safari, Android, iOS */
//   font-weight: 300;
// }

// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Liu+Jian+Mao+Cao&family=Long+Cang&display=swap');

@font-face {
  font-family: 'DolceVita-Regular';
  src:  url('/fonts/dolce_vita/dolce_vita-webfont.woff2') format('woff2'), /* Super Modern Browsers */
        url('/fonts/dolce_vita/dolce_vita_heavy_bold-webfont.woff') format('font-woff'), /* Pretty Modern Browsers */
        url('/fonts/dolce_vita/Dolce Vita.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
}

p,
ul li {
  font-size: $base-fontsize;
  font-family: $font1-reg;
  line-height: 180%;

  @include breakpoint(md) {
    font-size: $base-fontsize + 2px;
  }
}

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: $font1-light;
}

h1, .h1 {
  font-size: pxToRem(42px);

  @include breakpoint(md) {
    font-size: pxToRem(48px);
  }
}

h2, .h2 {
  font-size: pxToRem(36px);
  font-family: $font1-light;
  line-height: 150%;

  @include breakpoint(md) {
    font-size: pxToRem(48px);
  }
}

h3, .h3 {
  font-size: pxToRem(28px);
  font-family: $font1-light;
  line-height: 140%;

  @include breakpoint(md) {
    font-size: pxToRem(36px);
  }
}

.section-heading {
  margin-bottom: 50px;

  @include breakpoint(md) {
    margin-bottom: 120px;
  }

  &::before {
    content: "";
    background-color: $color-brand;
    width: 2px;
    height: 60px;
    display: inline-block;
    margin-right: 20px;
    position: relative;
    bottom: -2px;

    @include breakpoint(md) {
      height: 100px;
    }
  }
}

h4, .h4 {
  line-height: 1.6;
}

h5 {
  font-weight: 600;
}

.subheader1 {
  font-family: $font1-reg;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 210%;
  font-size: pxToRem(14px);

  @include breakpoint(md) {
    letter-spacing: 7px;
    font-size: pxToRem(16px);
  }
}

.subheader2 {
  font-family: $font1-reg;
  font-size: pxToRem(16px);
  text-transform: uppercase;
  letter-spacing: 7px;
  line-height: 200%;
}

.title1 {
  font-family: $font1-reg;
  text-transform: uppercase;
  letter-spacing: 6px;
  line-height: 210%;
  color: $color-brand;
}

.title2 {
  font-family: $font1-reg;
  letter-spacing: 1px;
  line-height: 180%;
  color: $color-brand;
  font-size: pxToRem(18px);
}

.caption1 {
  text-transform: uppercase;
  font-size: pxToRem(12px);
  letter-spacing: 3px;
  font-family: $font1-light;
  color: rgba($color-black, .5);
}

.caption2 {
  text-transform: uppercase;
  font-size: pxToRem(14px);
  letter-spacing: 2px;
  font-family: $font1-light;
}

.caption3 {
  text-transform: uppercase;
  font-size: pxToRem(11px);
  letter-spacing: 2px;
  font-family: $font1-light;
}

.vision-text {
  font-family: $font1-light;
  font-size: pxToRem(28px);
  line-height: 160%;

  @include breakpoint(md) {
    font-size: pxToRem(36px);
    line-height: 170%;
  }
}

.body1-light {
  font-size: pxToRem(14px);
  font-family: $font1-light;
  font-weight: 300;
}

.body2-reg {
  font-size: pxToRem(16px);
  font-family: $font1-reg;
  font-weight: 400;
}

.body2-light {
  font-size: pxToRem(16px);
  font-family: $font1-light;
  font-weight: 300;
}

.body-lead1 {
  font-size: pxToRem(24px);
  font-family: $font1-light;
  font-weight: 300;
}

.body-lead2 {
  font-size: pxToRem(24px);
  font-family: $font1-light;
  font-weight: 300;

  @include breakpoint(md) {
    font-size: pxToRem(36px);
    line-height: 170%;
  }
}

.quotation-wrapper {
  padding-left: 70px;
  &:after {
    content: url(/img/quote.svg);
    position: absolute;
    left: 15px;
    top: 10px;
  }
}