* {
  transition: all 200ms ease-in-out;
}

:root {
  font-size: $base-fontsize;
}

*:focus {
  outline: none !important;
}

html {
  background: #fafafa;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font1-reg;
  background: #fafafa;
}

section, .section {
  padding: 50px 0;

  @include breakpoint(md) {
    padding: 100px 0;
  }
}

.material-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

a {
  color: $color-brand;

  &:hover {
    color: $color-brand;
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

.link1 {
  font-size: pxToRem(14px);
  color: $color-brand;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font1-reg;
}

.caption {
  font-size: pxToRem(11px);
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// @media (min-width: 1200px) {
//   .container {
//       max-width: 960px;
//   }
// }