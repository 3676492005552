.footer {
  color: $color-white;
  background-color: $color-dark;
  padding: 0;
}

.footer-contact {
  padding-top: 60px;
  padding-bottom: 60px;

  @include breakpoint(md) {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.footer-details {
  @include breakpoint(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer-details-item {
  padding-top: 24px;

  @include breakpoint(md) {
    padding-top: 60px;
    margin-right: 100px;
  }

  span {
    display: block;
    opacity: .6;
    margin-bottom: 10px;
  }

  a {
    color: $color-white;
    font-size: pxToRem(15px);
    letter-spacing: 0.05em;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: $color-brand;
    }
  }

  .soc-med-item {
    a {
      display: inline-block;
      font-size: pxToRem(24px);
    }

    i {
      font-size: pxToRem(24px);
      padding-right: 30px;
    }
  }
}

.footer-end {
  > div {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  p {
    font-size: pxToRem(14px);
    letter-spacing: 0.03em;
    margin: 0;
  }

  a {
    color: $color-white;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: $color-brand;
    }
  }
}